<template>
  <div class="editorBox">
    <div class="top">
      <div class="item">
        <div class="text">代理商分成比例</div>
        <div class="value">{{ editData.agent_divide }}</div>
      </div>
      <div class="item">
        <div class="text">我的分成比例</div>
        <div class="value">{{ editData.my_divide }}</div>
      </div>
    </div>
    <div class="editlist">
      <div class="item">
        <div class="tit">门店名称<span>*</span></div>
        <div class="inp">
          <input type="text" v-model="name" />
        </div>
      </div>
      <div class="item">
        <div class="tit">门店分成比例<span>*</span></div>
        <div class="inp"><input type="text" v-model="divide_val" /></div>
        <div class="aftertext">%</div>
      </div>
      <div class="item" v-show="editData.show_fake_ratio==1" >
        <div class="tit">门店展示比例</div>
        <div class="inp"><input type="text" v-model="fake_ratio"/></div>
        <div class="aftertext">%</div>
      </div>
      <div class="item">
        <div class="tit">签约商务<span>*</span></div>
        <div class="value" @click="bShow">
          {{ sp_id_text }}<van-icon name="arrow-down" />
        </div>
      </div>
      <div class="item">
        <div class="tit">运维BD</div>
        <div class="value" @click="bdShow">
          {{ sp_id_maintain_text?sp_id_maintain_text:'无运维' }}<van-icon name="arrow-down" />
        </div>
      </div>
      <div class="item" v-show="editData.add_membership_auth==1" >
        <div class="tit">会员用户ID</div>
        <div class="inp"><input type="text" v-model="user_key" placeholder="请输入会员用户id"/></div>
      </div>
      <div class="item" v-show="editData.add_membership_auth==1" >
        <div class="tit">会员生效时间</div>
        <div class="inp date_right" @click="selectEffective">
          {{effective_date?effective_date:"请填写生效日期"}}
        </div>
      </div>
<!--      <div class="item">-->
<!--        <div class="tit">是否自营</div>-->
<!--        <div class="value" @click="isSelfShow">-->
<!--          {{ is_self_text?is_self_text:'' }}<van-icon name="arrow-down" />-->
<!--        </div>-->
<!--      </div>-->
      <div class="item">
        <div class="tit">营业时间</div>
        <div class="value" @click="openingTimeShow">
          {{ opening_time?opening_time:'' }}<van-icon name="arrow-down" />
        </div>
      </div>
      <div class="item">
        <div class="tit">备注信息</div>
        <div class="textarea">
          <textarea rows="3" v-model="remarks"></textarea>
        </div>
      </div>
    </div>
    <div class="btns">
      <button type="button" @click="cancerbtn">返回</button>
      <button type="button" @click="editClick">提交</button>
    </div>

    <van-popup v-model:show="BShow" position="bottom" round>
      <van-picker
        title="签约商务"
        :columns="sign_sp_list"
        @confirm="onConfirm"
        @cancel="onCancel"
        :default-index="signDef"
      />
    </van-popup>

    <van-popup v-model:show="BDShow" position="bottom" round>
      <van-picker
        title="运维BD"
        :columns="maintain_sp_list"
        @confirm="onbdConfirm"
        @cancel="onbdCancel"
        :default-index="maintainDef"
      />
    </van-popup>
    <van-calendar v-model:show="dateshow" :min-date="new Date(2024, 1, 1)"
                  :show-confirm="false" @confirm="onEffectiveConfirm" />
<!--    <van-popup v-model:show="is_self_show" position="bottom" round>-->
<!--      <van-picker-->
<!--          title="是否自营"-->
<!--          :columns="is_self_list"-->
<!--          @confirm="onIsSelfConfirm"-->
<!--          @cancel="onIsSelfCancel"-->
<!--          :default-index="is_self_def"-->
<!--      />-->
<!--    </van-popup>-->
    <van-popup v-model:show="openingStartShow" position="bottom" round>
      <van-datetime-picker
          v-model="openingStart"
          type="time"
          title="开始时间"
          @confirm="openingStartConfirm"
          @cancel="openingStartCancel"
      />
    </van-popup>
    <van-popup v-model:show="openingEndShow" position="bottom" round>
      <van-datetime-picker
          v-model="openingEnd"
          title="结束时间"
          type="time"
          @cancel="openingEndCancel"
          @confirm="openingOk"
      />
    </van-popup>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, toRefs, onMounted } from "vue";
import { Toast } from "vant";
import API from "@/axios";

const props = defineProps({
  editData: {
    type: Object,
    required: false,
  },
  infoData: {
    type: Object,
    required: false,
  },
});

const { editData,infoData } = toRefs(props);

const name = ref("");
const divide_val = ref(0);
const fake_ratio = ref(0);
const sp_id = ref(0);
const sp_id_text = ref("");
const sp_id_maintain = ref(0);
const sp_id_maintain_text = ref("");
const user_key = ref("");
const effective_date = ref("");
const remarks = ref("");
const BShow = ref(false);
const sign_sp_list = ref([]);
const BDShow = ref(false);
const maintain_sp_list = ref([]);
// const is_self_show = ref(false);
// const is_self = ref("");
// const is_self_text = ref("");
// const is_self_list = ref([]);
// const is_self_def = ref([]);
const dateshow = ref(false);
const emit = defineEmits(["subEdit", "closePup"]);

const signDef = ref("");
const maintainDef = ref("");

const hot_line = ref('')
const opening_time = ref('')
const openingStart = ref('')
const openingStartShow = ref(false)
const openingEnd = ref('')
const openingEndShow = ref(false)

function setinfo() {
  name.value = editData.value.name;
  divide_val.value = infoData.value.divide_val;
  fake_ratio.value = editData.value.fake_ratio;
  sp_id.value = editData.value.sp_id;
  sp_id_maintain.value = editData.value.sp_id_maintain;
  user_key.value = editData.value.user_key;
  effective_date.value = editData.value.effective_date;
  remarks.value = editData.value.remarks;

  hot_line.value = editData.value.hot_line;
  // 营业时间
  opening_time.value = editData.value.opening_time;
  if (!opening_time.value) {
    // 默认值
    opening_time.value = '00:00 ~ 23:59'
  }
  if (opening_time.value) {
    var opening_time_list = []
    opening_time_list = opening_time.value.split(' ~ ')
    if (opening_time_list.length == 2) {
      openingStart.value = opening_time_list[0]
      openingEnd.value = opening_time_list[1]
    }
  }

  for (let i = 0; i < editData.value.sign_sp_list.length; i++) {
    sign_sp_list.value.push(editData.value.sign_sp_list[i].title);
    if(editData.value.sign_sp_list[i].checked == 1){
      signDef.value = i
    }
  }

  for (let i = 0; i < editData.value.maintain_sp_list.length; i++) {
    maintain_sp_list.value.push(editData.value.maintain_sp_list[i].title);
    if(editData.value.maintain_sp_list[i].checked == 1){
      maintainDef.value = i
    }
  }

  sp_id_text.value = infoData.value.sign_user;
  sp_id_maintain_text.value = infoData.value.maintain_user;

  // for (let i = 0; i < editData.value.is_self_list.length; i++) {
  //   is_self_list.value.push(editData.value.is_self_list[i].title);
  //   if(editData.value.is_self_list[i].checked == 1){
  //     is_self_def.value = i
  //   }
  // }
  // is_self_text.value = infoData.value.is_self;
}

function bShow() {
  BShow.value = true;
}
function onConfirm(value, index) {
  BShow.value = false;
  sp_id_text.value = value;
  sp_id.value = editData.value.sign_sp_list[index].val;
}
function onCancel() {
  BShow.value = false;
}
function bdShow() {
  BDShow.value = true;
}
function onbdConfirm(value, index) {
  BDShow.value = false;
  sp_id_maintain_text.value = value;
  sp_id_maintain.value = editData.value.maintain_sp_list[index].val;
}
function onbdCancel() {
  BDShow.value = false;
}

const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
const onEffectiveConfirm = (value) => {
  dateshow.value = false;
  effective_date.value = formatDate(value);
};
function selectEffective(){
  dateshow.value = true;
}
// function isSelfShow() {
//   is_self_show.value = true;
// }
// function onIsSelfConfirm(value, index) {
//   is_self_show.value = false;
//   is_self_text.value = value;
//   is_self.value = editData.value.is_self_list[index].val;
// }
// function onIsSelfCancel() {
//   is_self_show.value = false;
// }
// 点击营业时间
function openingTimeShow(){
  openingStartShow.value = true
}
function openingStartCancel(){
  openingStartShow.value = false
}
function openingStartConfirm(value){
  openingStartShow.value = false
  openingEndShow.value = true
  openingStart.value = value
}
// 选择营业时间完成
function openingOk(value){
  openingEndShow.value = false
  openingEnd.value = value
  opening_time.value = openingStart.value + ' ~ ' + openingEnd.value
}
function openingEndCancel(){
  openingEndShow.value = false
  openingStartShow.value = true
}

function editClick() {
  API({
    url: "/api/editStore",
    method: "post",
    data: {
      store_id: editData.value.id,
      name: name.value,
      divide_val: divide_val.value,
      fake_ratio: fake_ratio.value,
      sp_id: sp_id.value,
      sp_id_maintain: sp_id_maintain.value,
      user_key: user_key.value,
      effective_date: effective_date.value,
      remarks: remarks.value,
      // is_self: is_self.value,
      hot_line: hot_line.value,
      opening_time: opening_time.value,
    },
  }).then((res) => {
    // console.log(res);
    if (res.data.status == 200) {
      emit("subEdit");
      Toast.success(res.data.msg);
    } else {
      Toast.fail(res.data.msg);
    }
  });
}
function cancerbtn() {
  emit("closePup");
}

onMounted(() => {
  setinfo();
});
</script>

<style lang="less" scoped>
.editorBox {
  padding: 20px;
}
.top {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 10px;
  padding-top: 10px;
  .item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    .text {
      color: @font-color;
      font-size: @subtitle-size;
      line-height: @subtitle-size;
      margin-bottom: 20px;
      font-weight: bolder;
      font-family: "Regular";
    }
    .value {
      color: @font-third-color;
      font-size: @font-size;
      line-height: @font-size;
      font-family: "Regular";
    }
  }
}
.editlist {
  overflow: hidden;
  .item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .tit {
      margin-right: 20px;
      span {
        color: #f00;
      }
    }
    .inp {
      width: auto;
      flex: 1;
      input {
        width: 100%;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .date_right{
      text-align: right;
    }
    .aftertext {
      font-weight: 600;
      color: #999;
    }
    .textarea {
      flex: 1;
      textarea {
        width: 100%;
        padding: 5px 10px;
        border: 1px solid #dfdfdf;
        border-radius: 10px;
        box-sizing: border-box;
        resize: none;
        background: #f9f9f9;
      }
    }
  }
}
.btns {
  display: flex;
  margin-bottom: 20px;
  button {
    background: none;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    margin-right: 20px;
    flex: 1;
    border: 3px solid @theme-secondary-color;
    color: @theme-secondary-color;
  }
  button:last-of-type {
    border: 3px solid @theme-secondary-color;
    background: @theme-secondary-color;
    color: @font-white-color;
    margin-right: 0;
    flex: 2;
  }
}
</style>
