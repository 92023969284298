<template>
  <div class="StoreDetail">
    <div class="contant">
      <div class="top">
        <div class="left">
          <div class="title">{{ infoData.name }}</div>
          <div class="addrtext">{{ infoData.address }}</div>
        </div>
        <div class="guide">
          <div class="icon" @click="guildeClick">
            <img src="@/assets/img/pos.png" alt="" />
          </div>
        </div>
        <div
          class="collect"
          :class="infoData.is_collect == 1 ? 'collected' : ''"
          @click="isCollect"
        >
          <div class="icon">
            <van-icon name="star" />
          </div>
          <div class="text">收藏</div>
        </div>
      </div>
      <div class="inputlist">
        <div class="item">
          <div class="tit">所属行业</div>
          <div class="text">{{ infoData.industry }}</div>
        </div>
        <div class="item">
          <div class="tit">门店账号</div>
          <div class="text">{{ infoData.account }}</div>
        </div>
        <div class="item">
          <div class="tit">门店联系人</div>
          <div class="text" @click="telecall(infoData.mobile)">
            {{ infoData.person }}/<span>{{ infoData.mobile }}</span>
          </div>
        </div>
        <div class="item">
          <div class="tit">门店比例</div>
          <div class="text">{{ infoData.divide_val }}%</div>
        </div>
        <div class="item" v-show="infoData.show_fake_ratio==1">
          <div class="tit">门店展示比例</div>
          <div class="text">{{ infoData.fake_ratio }}%</div>
        </div>
        <div class="item">
          <div class="tit">现场网络</div>
          <div class="text">{{ infoData.network }}</div>
        </div>
        <div class="item">
          <div class="tit">现场用电</div>
          <div class="text">{{ infoData.power }}</div>
        </div>
        <div class="item">
          <div class="tit">签约BD</div>
          <div class="text">{{ infoData.sign_user }}</div>
        </div>
        <div class="item">
          <div class="tit">运维BD</div>
          <div class="text">{{ infoData.maintain_user }}</div>
        </div>
        <div class="item" v-show="infoData.add_membership_auth==1">
          <div class="tit">会员用户ID</div>
          <div class="text">{{ infoData.user_key }}</div>
        </div>
        <div class="item" v-show="infoData.add_membership_auth==1">
          <div class="tit">会员生效时间</div>
          <div class="text">{{ infoData.effective_date }}</div>
        </div>
<!--        <div class="item">-->
<!--          <div class="tit">是否自营</div>-->
<!--          <div class="text">{{ infoData.is_self }}</div>-->
<!--        </div>-->
        <div class="item">
          <div class="tit">系统分成类型</div>
          <div class="text">{{ infoData.sys_cost_type_text }}</div>
        </div>
        <div class="item">
          <div class="tit">客服热线</div>
          <div class="text">{{ infoData.hot_line }}</div>
        </div>
        <div class="item">
          <div class="tit">营业时间</div>
          <div class="text">{{ infoData.opening_time }}</div>
        </div>
        <div class="item">
          <div class="tit">设备摆放位置</div>
          <div class="text">
            <div class="pic"><img :src="infoData.imgs" alt="" /></div>
          </div>
        </div>
        <div class="item">
          <div class="tit">BD和门头合照</div>
          <div class="text">
            <div class="pic"><img :src="infoData.business_img" alt="" /></div>
          </div>
        </div>
        <div class="item">
          <div class="tit">营业执照</div>
          <div class="text">
            <div class="pic"><img :src="infoData.license_img" alt="" /></div>
          </div>
        </div>
        <div class="item">
          <div class="tit">备注信息</div>
          <div class="text">{{ infoData.remarks }}</div>
        </div>
      </div>
    </div>
    <div class="btns">
      <button
        type="button"
        v-for="(item, index) in buttomBtn"
        :key="index"
        @click="clickbtn(item)"
      >
        {{ item.title }}
      </button>
    </div>

    <van-popup v-model:show="editshow" position="bottom" round>
      <editorBox @closePup="closePup" @subEdit="subEdit" :editData="editData" :infoData="infoData" v-if="editshow" />
    </van-popup>
  </div>
</template>

<script setup>
import editorBox from "@/views/StoreDetail/component/editorBox";
import { onMounted, ref } from "vue";
import API from "@/axios";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import mitts from "@/bus";

const route = useRoute();
const router = useRouter();

const buttomBtn = ref([]);
const infoData = ref({});
const editshow = ref(false);
const editData = ref({});

const address = ref("");
const lng = ref(null);
const lat = ref(null);

function telecall(val) {
  window.location.href = "tel://" + val;
}

function clickbtn(item) {
  let valname = item.val.substring(
    item.val.lastIndexOf("/") + 1,
    item.val.lastIndexOf("?")
  );

  
  if (valname == "intoArea") {
    intoArea();
  } else if (valname == "editStore") {
    editshow.value = true;
  } else {
    let url = item.val;
    router.push({
      path: "/event/event",
      query: { url: encodeURIComponent(url) },
    });

    mitts.emit("showLoading");
  }
}

function closePup() {
  editshow.value = false;
}

function subEdit() {
  getinfo();
  editshow.value = false;
}
//放入公海
function intoArea() {
  API({
    url: "/api/intoArea",
    method: "post",
    data: {
      store_id: route.query.id,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      router.push({
        path: "/StoreManagement",
        query: {},
      });
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

function getinfo() {
  API({
    url: "/api/storeDetail",
    method: "post",
    data: {
      store_id: route.query.id,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      buttomBtn.value = res.data.data.footer_button;
      infoData.value = res.data.data.info;
      editData.value = res.data.data.edit;

      address.value = res.data.data.info.address;
      lng.value = res.data.data.info.lng;
      lat.value = res.data.data.info.lat;
      //总线传参赋值底部菜单
      mitts.emit('navs', res.data.data.main_nav);
      mitts.emit("toptitle", false);
      mitts.emit('navindex', 'business');
    }else{
      Toast.fail(res.data.msg);
      router.go(-1);
    }
    
  });
}

function isCollect() {
  infoData.value.is_collect = !infoData.value.is_collect;
  API({
    url: "/api/collectStore",
    method: "post",
    data: {
      store_id: route.query.id,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      Toast.success(res.data.msg);
    }
  });
}

function guildeClick() {
  window.location.href = "https://uri.amap.com/marker?position=" + lng.value + "," + lat.value + "";
}

getinfo();

onMounted(() => {
  setTimeout(() => {
    mitts.emit("closeLoading");
    mitts.emit("toptitle", false);
  }, 500);
});
</script>

<style lang="less" scoped>
.StoreDetail {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100vw;
  background: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
}
.contant {
  flex-grow: 1;
  height: 10px;
  overflow-y: auto;
  background: #fff;
  box-shadow: 0 0 20px #d7d7d7;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.top {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 10px;
  .left {
    flex: 1;
    min-width: 0;
    margin-right: 10px;
    .title {
      color: @font-color;
      font-size: @subtitle-size;
      line-height: @subtitle-size;
      margin-bottom: 20px;
      font-weight: bolder;
      font-family: "Regular";
    }
    .addrtext {
      color: @font-color;
      font-size: @subtitle-size;
      line-height: @subtitle-size;
      height: @subtitle-size;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 10px;
    }
  }
  .collect {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    width: 80px;
    height: 80px;
    .icon {
      text-align: center;
      color: #999;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    .text {
      color: #999;
      font-size: 24px;
      line-height: 24px;
    }
  }
  .collected {
    border: 1px solid #f60;
    .icon {
      color: #f60;
    }
    .text {
      color: #f60;
    }
  }
  .guide {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    overflow: hidden;
    .icon {
      width: 100%;
      img {
        width: 100%;
        display: block;
      }
    }
  }
}
.inputlist {
  overflow: hidden;
  .item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .tit {
      font-size: @font-size;
      line-height: @font-size*2;
      color: @font-color;
      margin-right: 20px;
      font-weight: bolder;
      font-family: "Regular";
    }
    .text {
      font-size: @font-size;
      line-height: @font-size*2;
      color: @font-secondary-color;
      flex: 1;
      min-width: 0;
      display: flex;
      justify-content: flex-end;
      span {
        color: @theme-secondary-color;
        font-weight: bolder;
        font-family: "Regular";
      }
      .pic {
        width: 140px;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
}
.btns {
  display: flex;
  margin-bottom: 20px;
  button {
    background: none;
    font-size: 28px;
    height: 66px;
    line-height: 66px;
    border-radius: 10px;
    margin-right: 20px;
    flex: 1;
    border: 3px solid @theme-secondary-color;
    color: @theme-secondary-color;
  }
  button:last-of-type {
    border: 3px solid @theme-secondary-color;
    background: @theme-secondary-color;
    color: @font-white-color;
    margin-right: 0;
  }
}
</style>
